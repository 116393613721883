import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function RenovatiebedrijfPost() {
  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Renovatiebedrijf
            </span>
            <span className="mt-2 pb-6 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Renovatiebedrijf Joossens
            </span>
          </h1>
          <figure>
            <StaticImage
              src="../img/thumbnail_renovatiebedrijf.png"
              alt=""
              className="w-full rounded-lg"
            />
          </figure>
        </div>
        <div className="prose prose-indigo prose-lg text-gray-500 mx-auto">
          <blockquote className="mt-8">
            <p>Homigo geeft ons vooral, rust!</p>
          </blockquote>
          <p className="mt-8 text-xl text-gray-500 leading-8">
            Bouwbedrijf Joossens was al een tijdje op zoek naar een app die hen kon helpen. “We
            werkten ontzettend omslachtig met allerlei lijstjes en programma’s. Dit kostte veel tijd
            en soms raakte ik het overzicht wel eens kwijt”.
          </p>

          <h2>Overzicht en altijd op de hoogte </h2>
          <p>
            “Met Homigo weet ik dat als ik een klus opneem, het direct ergens instaat. We hoeven dus
            niet te onthouden wat de klant ook alweer gezegd had en aan het eind van de dag al die
            kleine klusjes nog moeten opschrijven. Dat deden we ook wel eens op papier, wat dan weer
            kwijtraakte. Dat gebeurt ons nu niet meer.”
          </p>
          <p>
            “Kijk! Hier zie je dat een collega van mij zijn uren heeft ingediend. Hij is 1,5 uur bij
            een klant geweest en dat kunnen wij dan direct zien”.
          </p>
          <p>
            Zelf vind ik het handig dat ik naar een klus toega, een werkbon invul op Homigo, er een
            paar foto’s bijvoeg en het zo overzichtelijk maak voor mijn werknemer.
          </p>
        </div>
      </div>
    </div>
  );
}
