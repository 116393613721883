import React from 'react';
import Layout from '../../components/layout';
import RenovatiebedrijfPost from '../../components/RenovatiebedrijfPost';
import BookDemo from '../../components/BookDemo';
import { SEO } from '../../components/SEO/SEO';

const RenovatiebedrijfPostPage = ({ location }) => (
  <Layout>
    <SEO location={location} pageMetadata={{ title: 'Renovation' }} />
    <RenovatiebedrijfPost />
    <BookDemo />
  </Layout>
);

export default RenovatiebedrijfPostPage;
